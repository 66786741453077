const barnabas = {
    id: 1,
    name: 'Barnabas',
    nameDescription: `His name was **Joseph**, but he was **called Barnabas by the Apostles** which means 'Son of encouragement' or 'Son of exhortation.'\n\m-b
  \n**Acts 4:36-37**
  \n*"Thus Joseph, who was also called by the apostles Barnabas (which means son of encouragement), a Levite, a native of Cyprus, sold a field that belonged to him and brought the money and laid it at the apostles' feet."`,
    familyMembers: 'Bible does not mention.',
    vocation: `**Levite (Acts 4:36)**\m-b
    **Missionary (Acts 13:2-3)**
    \n*“While they were worshiping the Lord and fasting, the Holy Spirit said, “Set apart for me Barnabas and Saul for the work to which I have called them.” 3Then after fasting and praying they laid their hands on them and sent them off.”\m-b
    **Encourager/Exhorter (Acts 4:36-37, Acts 9:26-27)**`,
    residence: `**Cyprus (Acts 4:36)**
    \nHe was a native of Cyprus\m-b
    **Antioch (Acts 11:25-26)**
    \nHe goes to find Saul (Paul) in Tarsus and takes him to Antioch, where they stayed for a year.\m-b
    **Jerusalem (Acts 11:30, Acts 15:2, Acts 15:12)**
    \nHe then left Antioch for Jerusalem with Saul (Paul) to give relief to the brothers during the famine.`,
  };

  export default barnabas;