import clayPot from './ClayPot2.png';
// Import any other images here

const imageMap = {
  '{clayPotImg}': clayPot,
  // Add future image variables here
};
const renderContent = (text) => {
  return text.split('\n').map((line, index) => {
    // Split the line by spaces to handle images and other text separately
    const parts = line.split(' ');

    if (line.includes('*') || line.includes('**') || line.includes('\m-b')) {
      // If the line contains the '\m-b' token, split it and add a margin-bottom
      const [content, marginBottom] = line.split('\m-b');
      return (
        <div key={index} style={{ marginBottom: line.includes('\m-b') ? '25px' : '0px' }}>
          {content.split('**').map((segment, idx) => {
            if (idx % 2 === 0) {
              // Check for italicized text
              return segment.split('*').map((subSegment, subIdx) => {
                if (subIdx % 2 === 0) {
                  return <span key={subIdx}>{subSegment}</span>;
                } else {
                  return <em key={subIdx}>{subSegment}</em>;
                }
              });
            } else {
              return <strong key={idx}>{segment}</strong>;
            }
          })}
        </div>
      );
    }
    else {
      return (
        <div key={index}>
          {parts.map((part, idx) => {
            if (imageMap[part]) {
              // If part matches an image placeholder, render the image
              return <img key={idx} src={imageMap[part]} alt={part} style={{ display: 'inline', margin: '0 5px', width: '200px' }} />;
            }
            
            else if (part.startsWith('{link:') && part.endsWith('}')) {
              // If part is a link, extract the URL and render a clickable link
              const urlMatch = part.match(/\{link:(.*?)\}/);
              if (urlMatch && urlMatch.length > 1) {
                const url = urlMatch[1];
                const domain = url.replace(/(^\w+:|^)\/\//, ''); // Remove protocol
                return <a key={idx} href={url}>{domain}</a>;
              }
            }
            else {
              // Otherwise, handle the text with formatting
              return part.split('**').map((segment, segIdx) => {
                if (segIdx % 2 === 0) {
                  // Check for italicized text
                  return segment.split('*').map((subSegment, subIdx) => {
                    if (subIdx % 2 === 0) {
                      return <span key={subIdx}>{subSegment} </span>;
                    } else {
                      return <em key={subIdx}>{subSegment} </em>;
                    }
                  });
                } else {
                  return <strong key={segIdx}>{segment} </strong>;
                }
              });
            }
          })}
        </div>
      );
    }

    
  });
};
export default renderContent;



