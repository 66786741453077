import React, { useState, useEffect } from 'react';
import axios from 'axios';

const serverUrl = 'https://savedclay.com'; // Added protocol to the URL

function CommunityPage() {
    const [posts, setPosts] = useState([]);
    const [newPostTitle, setNewPostTitle] = useState('');
    const [newPostContent, setNewPostContent] = useState('');

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await fetch('https://savedclay.com/api/posts');
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                console.log(data); // Handle the data as you wish
            } catch (error) {
                console.error('Error fetching posts:', error);
            }
        };
    
        fetchPosts();
    }, []);

    const handleTitleChange = (e) => {
        setNewPostTitle(e.target.value);
    };

    const handleContentChange = (e) => {
        setNewPostContent(e.target.value);
    };

    const handlePostSubmit = async () => {
        if (!newPostTitle.trim() || !newPostContent.trim()) {
            console.error('Post title or content cannot be empty.');
            return;
        }

        try {
            const response = await axios.post(`${serverUrl}/api/posts`, { title: newPostTitle, content: newPostContent });
            // Append the new post
            setPosts(prevPosts => [...prevPosts, { ...response.data, comments: [], showComments: false, newCommentContent: '' }]);
            setNewPostTitle('');
            setNewPostContent('');
        } catch (error) {
            console.error('Error creating post:', error);
        }
    };

    const handleCommentSubmit = async (postId, commentContent) => {
        if (!commentContent.trim()) {
            console.error('Comment content cannot be empty.');
            return;
        }

        try {
            const response = await axios.post(`${serverUrl}/api/posts/${postId}/comments`, { content: commentContent });
            setPosts(prevPosts => prevPosts.map(post => {
                if (post._id === postId) {
                    return { ...post, comments: [...post.comments, response.data] };
                }
                return post;
            }));
        } catch (error) {
            console.error('Error adding comment:', error);
        }
    };

    const handleShowComments = (postId) => {
        setPosts(prevPosts => prevPosts.map(post => {
            if (post._id === postId) {
                return { ...post, showComments: !post.showComments };
            }
            return post;
        }));
    };

    const handleDeletePost = async (postId) => {
        try {
            await axios.delete(`${serverUrl}/api/posts/${postId}`);
            setPosts(prevPosts => prevPosts.filter(post => post._id !== postId));
        } catch (error) {
            console.error('Error deleting post:', error);
        }
    };

    return (
        <div className="community-page">
            <h2>Community Forum (Work in progress...)</h2>
            <div className="post-form">
                <input
                    type="text"
                    placeholder="Enter post title"
                    value={newPostTitle}
                    onChange={handleTitleChange}
                />
                <textarea
                    placeholder="Enter post content: Use **Bold** to make text bold, and *italic* to make text italicized."
                    value={newPostContent}
                    onChange={handleContentChange}
                />
                <button onClick={handlePostSubmit}>Submit Post</button>
            </div>
            <div className="posts">
                {posts.map(post => (
                    <div key={post._id} className="post">
                        <button className="delete-button" onClick={() => handleDeletePost(post._id)}>x</button>
                        <h3>{post.title}</h3>
                        <p>{post.content}</p>
                        <p id="post-date">Date: {post.date ? new Date(post.date).toLocaleString() : ''}</p>
                        <button id="show-hide-comments" onClick={() => handleShowComments(post._id)}>
                            {post.showComments ? "Hide Comments" : "Show Comments"}
                        </button>
                        {post.showComments && (
                            <div className="comments">
                                {Array.isArray(post.comments) && post.comments.map((comment, index) => (
                                    <div key={index} className="comment">
                                        <p>{comment.content}</p> {/* Adjust based on your comment structure */}
                                    </div>
                                ))}
                                <div className="comment-form">
                                    <textarea
                                        placeholder="Add your comment..."
                                        value={post.newCommentContent || ""}
                                        onChange={(e) => {
                                            const updatedPosts = posts.map(p => {
                                                if (p._id === post._id) {
                                                    return { ...p, newCommentContent: e.target.value };
                                                }
                                                return p;
                                            });
                                            setPosts(updatedPosts);
                                        }}
                                    />
                                    <button onClick={() => handleCommentSubmit(post._id, post.newCommentContent)}>
                                        Submit Comment
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default CommunityPage;
