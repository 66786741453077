import React, { useState } from 'react';
import verses from './verses.json'; // Import the verses JSON file

const MemoryGame = () => {
  const [currentVerse, setCurrentVerse] = useState(null);
  const [userInput, setUserInput] = useState('');
  const [feedback, setFeedback] = useState('');
  const [displayedPart, setDisplayedPart] = useState('');
  const [streak, setStreak] = useState(0); // Add state for current streak

  const getRandomVerse = () => {
    const randomIndex = Math.floor(Math.random() * verses.Verses.length);
    const verse = verses.Verses[randomIndex];
    const isTitle = Math.random() < 0.5;
    setDisplayedPart('content');
    setCurrentVerse(verse);
    setUserInput('');
    setFeedback('');
  };

  const removePunctuation = (text) => {
    return text.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, "").replace(/\s{2,}/g, " ").trim();
  };

  const handleSubmit = () => {
    const cleanedUserInput = removePunctuation(userInput.trim().toLowerCase());
    const correctAnswer = removePunctuation(currentVerse[displayedPart === 'title' ? 'content' : 'title'].trim().toLowerCase());

    if (cleanedUserInput === correctAnswer) {
      setFeedback(`Correct! ${currentVerse[displayedPart === 'title' ? 'content' : 'title']}`);
      setStreak(streak + 1); // Increment streak
      setTimeout(getRandomVerse, 2000);
    } else {
      setFeedback(`Incorrect :( The correct answer is: ${currentVerse[displayedPart === 'title' ? 'content' : 'title']}`);
      setStreak(0); // Reset streak
      setTimeout(getRandomVerse, 5000);
    }
  };

  return (
    <div className="memory-game">
      <h2>Memory Game</h2>
      <p>Current Streak: {streak}</p> {/* Display current streak */}
      {currentVerse ? (
        <>
          <p>{currentVerse[displayedPart]}</p>
          <input
            type="text"
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            placeholder={`Enter the ${displayedPart === 'title' ? 'content' : 'title'}`}
          />
          <button onClick={handleSubmit}>Submit</button>
          <p>{feedback}</p>
        </>
      ) : (
        <button onClick={getRandomVerse}>Start Game</button>
      )}
    </div>
  );
};

export default MemoryGame;
