import React, { useState } from 'react';
import renderContent from './renderContent'; //Render the content in a formatted way.

function CharacterSection({ title, content }) {
    const [isOpen, setIsOpen] = useState(false);
  
    const toggleSection = () => {
      setIsOpen(!isOpen);
    };
  
    
    
  
    return (
      <div className="character-section">
        <div className="character-section-title" onClick={toggleSection}>
          {title}
        </div>
        {isOpen && <div className="character-section-content active">{renderContent(content)}</div>}
      </div>
    );
}

function CharacterStudyPage({ character }) {
  return (
    <div className="character-study-page">
      <h2>{character.name}</h2>
      <CharacterSection title="Name" content={character.nameDescription} />
      <CharacterSection title="Family Members" content={character.familyMembers} />
      <CharacterSection title="Vocation/Occupation" content={character.vocation} />
      <CharacterSection title="Places of Residence" content={character.residence} />
    </div>
  );
}

export default CharacterStudyPage;
