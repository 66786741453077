const testCharacter = {
    id: 2,
    name: 'Test Character',
    nameDescription: `This is a test character.`,
    familyMembers: 'Family.',
    vocation: `Test vocation.\nTest vocation line 2.\n**Test vocation line 3**\m-b10\nTest vocation line 4.\n\n**Test vocation line 5**\m-b20\nTest vocation line 6.`,
    residence: `Test residence line 1.\n\nTest residence line 2.\m-b15\n**Test residence line 3**\nTest residence line 4.\n
  \nTest residence line 5.`,
  };

  export default testCharacter;